/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import styled from 'styled-components';
import { Form, Field, FieldProps, useFormikContext } from 'formik';
import {
  Title,
  View,
  AddressForm,
  ComplexityForm,
  CaseDescriptionForm,
  SimpleCheckMark,
  Text,
  ErrorMessage,
  TextField,
  ColumnLayout,
  useAsyncAction,
  Button,
  NameForm,
  PhoneForm,
  VariantWorkaround,
} from '@adac/core-view';

import { Select, MenuItem } from '@material-ui/core';
import {
  __,
  Company,
  getCompanyId,
  ProductTypes,
  capitalize,
  generateDummyCommisioningId,
} from '@adac/core-model';

import { CardContent } from '../common/CustomCard';
import NewCaseButtonArea from './NewCaseButtonArea';

import { NewRegularCaseFormValues } from './NewRegularCase';
import { requestActiveCompanyList } from '../../dataProvider/companies';

const FieldsStyles = styled(View)`
  & > * {
    margin-bottom: 16px;
  }
  margin-bottom: 64px;
`;

const CardContentWithMargin = styled(CardContent)`
  margin: 0 auto !important;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;
`;

const Checkmark = styled(SimpleCheckMark)`
  margin-bottom: 0;
`;

const CheckboxText = styled(Text)`
  padding-left: 10px;
`;

// we block "Enter", so that the form can only be submitted by clicking the button
function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

interface NewRegularCaseFormProps {
  handleCancel: () => void;
  children?: React.ReactNode;
}

export const NewRegularCaseForm = ({
  handleCancel,
  children,
}: NewRegularCaseFormProps) => {
  const { isSubmitting, setFieldValue, values, errors, isValid } =
    useFormikContext<NewRegularCaseFormValues>();
  const { resource: activeCompanies } = useAsyncAction(
    requestActiveCompanyList
  );

  return (
    <Form onKeyDown={onKeyDown}>
      {children}
      <CardContentWithMargin>
        <FieldsStyles>
          <Title>{__('Commission ID of the case')}</Title>
          <ColumnLayout
            ratio='0.85fr 0.15fr 10px 1fr'
            style={{ alignItems: 'center' }}
          >
            <Field
              name='commissioningReference'
              render={({
                field,
              }: FieldProps<
                'commissioningReference',
                NewRegularCaseFormValues
              >) => (
                <TextField
                  {...field}
                  badgeEqualsPlaceholder
                  badgeTitle={__('Prefix (2200111709-53020-Her)')}
                />
              )}
            />

            <Button
              onClick={() =>
                setFieldValue(
                  'commissioningReference',
                  generateDummyCommisioningId(Date.now)
                )
              }
              type='button'
            >
              &lt;-New
            </Button>
            <Title>/</Title>

            <Field
              name='contactManagerId'
              render={({
                field,
              }: FieldProps<'contactManagerId', NewRegularCaseFormValues>) => (
                <VariantWorkaround variant='outlined'>
                  <Select {...field} label={__('Company id')}>
                    {activeCompanies
                      ?.sort((a: Company, b: Company) => a.id - b.id)
                      ?.map((company: Company) =>
                        company.id ? (
                          <MenuItem
                            key={company.id}
                            selected={
                              getCompanyId(company) === values?.contactManagerId
                            }
                            value={getCompanyId(company)}
                          >
                            {company.name}
                          </MenuItem>
                        ) : null
                      )}
                  </Select>
                </VariantWorkaround>
              )}
            />
          </ColumnLayout>

          <ColumnLayout ratio='1fr'>
            <Field
              name='productType'
              render={({
                field,
              }: FieldProps<'productType', NewRegularCaseFormValues>) => (
                <VariantWorkaround variant='outlined'>
                  <Select {...field} label={__('ProductType')}>
                    {Object.values(ProductTypes).map((t) => (
                      <MenuItem
                        key={t}
                        selected={values?.productType === t}
                        value={t}
                      >
                        {capitalize(t)}
                      </MenuItem>
                    ))}
                  </Select>
                </VariantWorkaround>
              )}
            />
          </ColumnLayout>

          <ColumnLayout ratio='1fr 1fr' style={{ alignItems: 'center' }}>
            <Field
              name='damageNumber'
              render={({
                field,
              }: FieldProps<'damageNumber', NewRegularCaseFormValues>) => (
                <TextField
                  {...field}
                  badgeEqualsPlaceholder
                  badgeTitle={__('damageNumber')}
                  disabled
                />
              )}
            />
            <Field
              name='productNumber'
              render={({
                field,
              }: FieldProps<'productNumber', NewRegularCaseFormValues>) => (
                <TextField
                  {...field}
                  badgeEqualsPlaceholder
                  badgeTitle={__('ProductNumber')}
                  disabled
                />
              )}
            />
          </ColumnLayout>

          <Title>{__('Your address')}</Title>
          <AddressForm showLocationBtn={false} showEditButton={false} />

          <Title>{__('How can we reach you')}</Title>

          <NameForm />

          <PhoneForm isCustomerSide={false} />

          <Text paddingTop='4px' fontSize='1.6rem'>
            {__('Description is mandantory if no number given')}
          </Text>

          <CheckboxContainer
            onClick={() =>
              setFieldValue('receiveStatusViaSMS', !values.receiveStatusViaSMS)
            }
          >
            <Checkmark checked={values.receiveStatusViaSMS} />
            <CheckboxText as='p'>{__('Send SMS checkbox')}</CheckboxText>
          </CheckboxContainer>

          <Title marginTop='18px'>{__('Is your door closed')}</Title>
          <ComplexityForm />

          <Title paddingTop='20px'>{__('Description not visible')}</Title>
          <CaseDescriptionForm />
          {errors.description && (
            <ErrorMessage>{__(errors.description)}</ErrorMessage>
          )}
        </FieldsStyles>

        <NewCaseButtonArea
          cancel={handleCancel}
          disabled={isSubmitting || !isValid}
          isLoading={isSubmitting}
        />
      </CardContentWithMargin>
    </Form>
  );
};
